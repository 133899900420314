@media screen and (max-width: 1280px) {
    /* intersection */
    .intersection-wrapper {
        display: flex;
        flex-direction: column;
        padding: 6vh 0;
        max-width: 80%;
    }

    .first-intersection-figure-3 {
        padding-top: 40%;
    }

    .inter-container-text-left-col {
        padding-left: 10%;
    }

    .inter-container-text-right-col {
        padding-right: 10%;
    }

    .section-heading {
        font-size: 45px;
    }

    .tom-morton-2 {
        top: 50%;
    }

    .third-intersection-figure-3,
    .third-intersection-figure-4 {
        padding-top: 20%;
    }

    .third-intersection-figure-6 {
        padding-top: 40%;
    }

    .third-inter-figure-text-wrapper-1 .d-flex:first-child {
        padding-top: 10vh;
    }

    .fourth-section-hero-wrapper {
        max-width: 90%;
    }

    .fourth-intersection-img-caption {
        max-width: 80%;
        padding: 8vh 0 6vh 0;
    }

    .fifth-intertext-wrapper {
        width: 100%;
    }

    .fifth-intercontainer {
        width: 95%;
        padding: 2vh 0;
    }

    .second-intersection-figure-5 {
        padding-top: 10%;
        padding-right: 20%;
    }

    /*  sidebar */

    .sidebar-menu {
        width: 30%;
    }

    .main-line {
        height: 100vh;
    }

    .sidebar-social-row {
        padding: 1.5rem 0.2rem 1.5rem 0;
    }

    .sidebar-carousel-container {
        width: 70%;
    }

    .sidebar-carousel-slide {
        right: 30%;
    }

    .sidebar-menu .sidebar-menu-row {
        margin-left: 1vw;
    }

    .sidebar-menu-item {
        width: 100% !important;
        margin: 30px 0;
    }

    .mySlides img {
        -o-object-position: 40% 90%;
        object-position: 40% 90%;
    }

    #welcome-div:hover,
    #climat-liter-div:hover {
        background-color: transparent;
        color: #000;
    }

    #cornerstone-div:hover,
    #close-of-div:hover {
        background-color: transparent;
    }

    #making-waves-div:hover,
    #after-november-div:hover {
        background-color: transparent;
    }

    .main-menu-row {
        height: 90vh;
    }

    .flow-wrapper {
        width: 75vw;
    }

    .flow-wrapper-slide {
        display: none;
    }

    .welcome-text-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 4vh 0;
    }

    .welcome-text-wrapper .container-text-right-col {
        padding: 0 15%;
    }

    .container-text-wrapper {
        width: 100%;
    }

    .welcome-text-wrapper .col {
        font-size: 1.2em;
    }

    .welcome-video-container {
        padding: 0;
    }

    /* iframe {
        height: 36vh;
    } */

    .welcome-video-container {
        width: 80%;
    }

    .main-menu-item {
        padding-left: 0;
        text-align: center;
    }

    #welcome-section .welcome-img {
        position: static !important;
        width: 50% !important;
    }

    /* .making-waves-container {
        width: 80%;
    } */

    #christina-wong-container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    #christina-wong-container .making-waves-text-wrapper {
        margin-left: 0;
    }

    .welcome-credits-container {
        padding: 10vh 0 20vh 0;
    }

    #making-waves-section {
        padding-top: 8vh;
    }

    .making-waves-heading {
        padding-bottom: 1em;
    }

    .making-waves-text-wrapper {
        width: 90%;
    }

    #sculpture-1 {
        left: 0;
        top: -12%;
        max-width: 12%;
    }

    #sculpture-2 {
        left: -10%;
        top: -12%;
        max-width: 35%;
    }

    #sculpture-3 {
        right: 0;
        bottom: 0;
        max-width: 16%;
    }

    #sculpture-4 {
        right: 0;
        bottom: 0;
        max-width: 35%;
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    #glasgow-tool-1 {
        top: 90%;
        left: 75%;
        max-width: 10%;
    }

    #glasgow-tool-2 {
        top: 85%;
        left: 75%;
        max-width: 30%;
    }

    #karen-1 {
        left: -15%;
        bottom: 0;
        max-width: 20%;
    }

    #karen-2 {
        right: 5%;
        bottom: 0;
        max-width: 20%;
    }

    #potr-1 {
        left: -20%;
        bottom: 45%;
        max-width: 20%;
    }

    #potr-2 {
        left: -30%;
        bottom: 40%;
        max-width: 40%;
    }

    #potr-3 {
        right: -6%;
        bottom: 5%;
        max-width: 16%;
    }

    #james-pfaff-1 {
        left: -40%;
        bottom: 50%;
        max-width: 16%;
    }

    #james-pfaff-2 {
        right: -10%;
        bottom: 0;
        max-width: 16%;
    }

    #james-pfaff-3 {
        right: -15%;
        bottom: 0;
        max-width: 40%;
    }

    #allot-me-1 {
        left: -18%;
        bottom: 30%;
        max-width: 20%;
    }

    #zero-waste-1 {
        left: -15%;
        bottom: 36%;
        max-width: 20%;
    }

    #zero-waste-2 {
        right: -10%;
        bottom: 2%;
        max-width: 25%;
    }

    #circular-arts-1 {
        -webkit-transform: rotate(-200deg);
        -ms-transform: rotate(-200deg);
        transform: rotate(-200deg);
        position: absolute;
        left: -30%;
        bottom: 12%;
        max-width: 30%;
    }

    /***************    section general   ****************/

    .heading-text {
        font-size: 20px;
        margin-bottom: 3em;
    }

    .container-text-left-col {
        padding-left: 15%;
    }

    .container-text-right-col {
        padding-right: 15%;
    }

    .figure-text-wrapper {
        padding: 2vh 0;
    }

    /***************     sustainable section   ***************/

    .sustainable-section {
        padding-bottom: 10%;
    }

    .sustainable-heading-text {
        top: 40%;
        left: 35%;
        max-width: 80%;
    }

    .sustainable-figure-3,
    .sustainable-figure-4 {
        padding: 1em;
    }

    .sustainable-figure-10 {
        padding-top: 20%;
    }

    .sustainable-figure-11,
    .sustainable-figure-12 {
        margin-top: 5%;
    }

    /***************  after november   ***********/

    .after-november-container-1 .row {
        grid-template-columns: 1fr;
    }

    .after-november-heading {
        top: 40%;
        font-size: 45px;
    }

    .after-november-heading-text {
        top: 50%;
    }

    /***************  climate literacy  ***************/

    .climate-literacy-section {
        padding-bottom: 10%;
    }

    .climate-literacy-figure-11 {
        margin-top: 3%;
    }

    /***************  close of play  ***************/

    #close-of-play-section {
        margin-top: 0;
    }

    .close-of-main-wrapper {
        max-width: 90%;
    }

    .close-of-main-wrapper {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 850px) {
    /*   intersections  */

    .inter-figure-text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5em;
    }

    .intersection-figure {
        padding: 0 10%;
    }

    .inter-container-text-col {
        padding: 0 10%;
        width: 90%;
    }

    .third-inter-figure-text-wrapper-1 .d-flex:first-child {
        padding-top: 0;
    }

    .figures-wrapper {
        flex-direction: column;
    }

    .third-intersection-figure-3 {
        max-width: 90%;
    }

    .third-inter-figure-text-wrapper-3 {
        flex-direction: column-reverse;
    }

    /* maikng waves */

    .waves-arrow-col {
        display: none;
    }

    /* sustainable */

    .section-heading {
        font-size: 35px;
    }

    .figure-text-wrapper-1 .d-flex {
        align-items: center;
        padding: 5% 15% 5% 0;
    }

    .figure-text-wrapper-2 .d-flex {
        align-items: center;
        padding: 5%;
    }

    .figure-text-wrapper-1,
    .figure-text-wrapper-2 {
        grid-template-columns: 1fr;
    }

    .sustainable-figure-11 {
        margin-left: 0;
    }

    .sustainable-figure-12 {
        margin-top: 3%;
    }

    /*  after november  */

    .after-november-heading {
        top: 40%;
    }

    .after-november-heading-text {
        top: 48%;
    }

    .after-november-figure-2 {
        right: 4%;
        top: 40%;
    }

    .november-line-wrapper {
        display: none;
    }

    /* climate literacy */

    .climate-literacy-heading-text {
        top: 40%;
    }

    /* close of play */

    .close-of-main-wrapper {
        grid-gap: 1em;
    }

    .close-of-col {
        -ms-grid-rows: auto auto auto;
        grid-template-rows: auto auto auto;
    }

    #sustaining .close-of-col {
        -ms-grid-rows: auto auto auto;
        grid-template-rows: auto auto auto;
    }

    .close-of-data-row {
        padding: 1em 1em 0 1em;
    }

    .more-info-row {
        display: none !important;
    }

    .more-info-col {
        justify-content: flex-start;
    }
}

@media (min-width: 600px) and (max-width: 1025px) {
    .welcome-credits-container {
        font-size: 1.4em;
    }

    .main-menu-item-text {
        font-size: 40px !important;
    }
}
